<template>
  <div class="user-address">
    <!-- 页头 -->
    <div class="block_title">
      <span class="user-icon"></span>
      <span class="user-title">地址管理</span>
      <div class="x20"></div>
    </div>
    <!-- 收货地址展示 -->

    <ul class="address-content clearfix">
      <li v-for="(item, index) in addressList" :key="index">
        <div class="name">{{ item.real_name }}</div>
        <div class="phone">{{ item.phone }}</div>
        <div class="text">{{ item.city_name }}</div>
        <div class="text">{{ item.detail }}</div>
        <div class="edit-box">
          <span @click="bindDefault(item)" v-if="item.is_default != 1"
            >设为默认地址</span
          >
          <span @click="edit(item)">修改</span>
          <span @click="bingDelete(item, index)">删除</span>
        </div>
        <div class="moren" v-if="item.is_default == 1">默认</div>
      </li>
      <li class="addbox" @click="addAddress">
        <div class="box">
          <img src="../../../assets/images/add.png" alt="" />
          <p>添加新地址</p>
        </div>
      </li>
    </ul>

    <!-- 添加收货地址弹出框 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="700px"
      :before-close="handleClose"
    >
      <!-- 表单 -->
      <el-form
        ref="addressFormRef"
        :model="addressFormData"
        :rules="addressFormRules"
        size="small"
      >
        <el-row type="flex" :gutter="24">
          <!-- 姓名 -->
          <el-col>
            <el-form-item prop="real_name">
              <el-input
                placeholder="姓名"
                v-model="addressFormData.real_name"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- 手机号码 -->
          <el-col>
            <el-form-item prop="phone">
              <el-input
                placeholder="手机号码"
                v-model="addressFormData.phone"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 地区列表 -->
        <el-row type="flex" :gutter="24">
          <el-col>
            <el-form-item prop="city_id">
              <el-cascader
                v-model="addressFormData.city_id"
                :options="areaList"
                :props="{ value: 'city_id' }"
                placeholder="请选择省/市/区"
                style="width: 100%"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item prop="post_code">
              <el-input
                placeholder="邮政编码"
                v-model="addressFormData.post_code"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 详细地址-->
        <el-row>
          <el-form-item prop="detail">
            <el-input
              type="textarea"
              placeholder="详细地址"
              v-model="addressFormData.detail"
              style="width: 100%"
            >
            </el-input>
          </el-form-item>
        </el-row>
        <!-- 是否为默认 -->
        <el-row>
          <el-form-item prop="is_default">
            <el-checkbox v-model="addressFormData.is_default"
              >设为默认</el-checkbox
            >
          </el-form-item>
        </el-row>
      </el-form>
      <!-- 取消/确定 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { getAddressListApi, getAreaListApi, addAddressSubmitApi, editAddressSubmitApi } from '@/api/user';
  export default {
    name: "addressList",
    data() {
      return {
        addressList: [],
        dialogVisible: false,
        addressFormData: {
          real_name: '',
          phone: '',
          city_id: [],
          post_code: '',
          detail: '',
          is_default: 0
        },
        addressFormRules: {
          real_name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
          phone: [{ required: true, message: '请输入手机号码', trigger: 'blur' }],
          city_id: [{ required: true, message: '请选择省/市/区', trigger: 'blur' }],
          post_code: [{ required: true, message: '请输入邮政编码', trigger: 'blur' }],
          detail: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
        }, //添加收货地址验证规则
        areaList: [], //获取地区列表数据
        dialogTitle: '',
        uid: ''
      }

    },
    created() {
      this.getAddressList()
    },
    methods: {
      // 获取用户地址列表
      getAddressList() {
        getAddressListApi().then(res => {
          this.addressList = res.data.result
          //   console.log(this.addressList)
          this.addressList.forEach(item => {
            let addr = JSON.parse(item.merger_code_name)
            item.city_name = ''
            Object.keys(addr).forEach(key => {
              item.city_name += addr[key]
              //   console.log(item.city_id, item.city_name)
            })
          });
        })
      },
      // 添加收货地址弹窗
      addAddress() {
        this.dialogTitle = '添加收货地址'
        this.dialogVisible = true
        this.getAreaList()
      },
      // 获取地区列表 
      getAreaList() {
        getAreaListApi().then(res => {
          // console.log(res.data.result.cityInfo)
          this.areaList = res.data.result.cityInfo
        })
      },
      //   提交
      handleSubmit() {
        if (this.addressFormData.is_default) {
          this.addressFormData.is_default = 1
        } else {
          this.addressFormData.is_default = 0
        }
        this.$refs.addressFormRef.validate(valid => {
          if (!valid) return false
          this.addressFormData.city_id = this.addressFormData.city_id[this.addressFormData.city_id.length - 1]
          if (!this.uid) {
            addAddressSubmitApi(this.addressFormData).then(res => {
              console.log(res)
              this.getAddressList()
            })
          } else {
            this.editAddressSubmit()
          }
          this.handleClose()
        })
      },
      //   修改地址提交
      editAddressSubmit() {
        editAddressSubmitApi(this.uid, this.addressFormData).then(res => {
          this.addressFormData = {}
          this.getAddressList()
        })
      },
      //   修改地址弹窗
      edit(item) {
        let { real_name, phone, city_id, post_code, detail, is_default, address_id, uid } = item
        if (is_default) is_default = true
        this.uid = uid
        this.addressFormData = { real_name, phone, city_id, post_code, detail, is_default, address_id }
        console.log(this.addressFormData)
        this.dialogVisible = true
        this.dialogTitle = '修改收货地址'
        this.getAreaList()
      },
      //   设置默认地址
      bindDefault(item) {
        this.$confirm('您确定将当前地址设置为默认地址吗？', '修改默认地址确认', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let { real_name, phone, city_id, post_code, detail, is_default, address_id, uid } = item
          this.uid = uid
          is_default = 1
          this.addressFormData = { real_name, phone, city_id, post_code, detail, is_default, address_id }
          this.editAddressSubmit()
        }).catch(() => {
          this.$message.info('已取消');
        });
      },
      bingDelete() {},
      //   关闭收货地址弹出框
      handleClose() {
        this.dialogVisible = false
        this.uid = ''
        this.$refs.addressFormRef.resetFields()
        this.addressFormData = {}
      }
    },

  }
</script>

<style lang="less" scoped>
.user-address {
  padding: 40px;

  .user-icon {
    border: 2px solid #ca151e;
  }

  .user-title {
    font-size: 16px;
    padding-left: 20px;
  }

  .x20 {
    height: 1px;
    background: #efefef;
    margin-top: 20px;
  }

  .address-content {
    li {
      position: relative;
      float: left;
      width: 200px;
      min-height: 120px;
      margin-top: 20px;
      margin-left: 40px;
      padding: 22px 27px;
      border: 1px solid #eaeaea;

      .moren {
        position: absolute;
        right: 0;
        top: 0;
        width: 56px;
        height: 23px;
        line-height: 23px;
        text-align: center;
        color: #fff;
        background: #e93323;
      }

      .name {
        color: #282828;
        font-size: 16px;
      }

      .phone {
        margin-top: 14px;
        margin-bottom: 8px;
        font-size: 12px;
      }

      .text {
        color: #999999;
        font-size: 14px;
      }

      .edit-box {
        opacity: 0;
        position: absolute;
        right: 14px;
        bottom: 12px;
        color: #e93323;

        span {
          margin-left: 5px;
          cursor: pointer;
        }
      }

      &.addbox {
        .box {
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -25px;
          text-align: center;
          width: 100%;
          color: #c8c8c8;
          font-size: 14px;

          img {
            display: inline-block;
            width: 27px;
            height: 27px;
          }
        }
      }

      &:hover {
        .edit-box {
          opacity: 1;
          transition: all 0.6s ease;
        }
      }
    }
  }
}
</style>